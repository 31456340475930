import React, { Component } from 'react';
import { setDocumentTitle } from '../../../lib/utils/siteUtils';
import { Link } from 'react-router-dom';

const LAST_UPDATED = 'December 31, 2018';

class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);
    setDocumentTitle('Privacy Policy');
  }
  render() {
    return (
      <div className="legal-wrapper">
        <div className="p-3 bg-primary text-light text-center">
          <h1 className="mb-0">Privacy policy</h1>
        </div>

        <div className="container-fluid text-primary bg-light py-5">
          <div className="row mb-5 justify-content-md-center">
            <div className="col-lg-5 col-md-7 col-sm-12">
              <div data-slicetype="html" className="slice">
                <p>Last updated {LAST_UPDATED}</p>

                <p>
                  This Privacy Policy describes how Fluidware srl and its affiliates ("Fluidware," "we," "our" or "us")
                  collect, use, and share information in connection with your use of our websites (including
                  www.authkeys.io), services, and applications (collectively, the "Services"). This Privacy Policy (the
                  "Privacy Policy") does not apply to information our customers may process when using our Services.
                </p>

                <p>
                  We may collect and receive information about users of our Services ("users," "you," or "your") from
                  various sources, including: (i) information you provide through your user account on the Services
                  (your "Account") if you register for the Services; (ii) your use of the Services; and (iii) from
                  third-party websites, services, and partners.
                </p>

                <p>
                  We recommend that you read this Privacy Policy in full to ensure you are fully informed.&nbsp;If you
                  have any questions about this Privacy Policy or AuthKeys's data collection, use, and disclosure
                  practices, please contact us at&nbsp;<a href="mailto:privacy@authkeys.io">privacy@authkeys.io</a>.
                </p>
              </div>

              <h2 id="information-we-collect">1. Information We Collect</h2>

              <ol type="A">
                <li>
                  <strong>Information You Provide</strong>

                  <ol type="I">
                    <li>
                      <strong>Account Registration.</strong> When you register for an Account, we may ask for your
                      contact information, including items such as name, company name, address, email address, and
                      telephone number. If you choose to refer a friend to our Services, we may also collect your
                      friend’s email address so that we may send them a referral or promotional code to sign up for our
                      Services.
                    </li>

                    <li>
                      <strong>Payment Information.</strong> When you add your financial account information to your
                      Account, that information is directed to our third-party payment processor. We do not store your
                      financial account information on our systems; however, we have access to, and may retain,
                      subscriber information through our third-party payment processor.
                    </li>

                    <li>
                      <strong>Communications.</strong> If you contact us directly, we may receive additional information
                      about you such as your name, email address, phone number, the contents of the message and/or
                      attachments you may send us, and any other information you may choose to provide. We may also
                      receive a confirmation when you open an email from us.
                    </li>
                  </ol>

                  <p>
                    The personal information that you are asked to provide, and the reasons why you are asked to provide
                    it, will be made clear to you at the point we ask you to provide your personal information.
                  </p>
                </li>

                <li>
                  <strong>Information We Collect When You Use Our Services.</strong>

                  <ol type="I">
                    <li>
                      <strong>Cookies and Other Tracking Technologies.</strong> As is true of most websites, we gather
                      certain information automatically and store it in log files. In addition, when you use our
                      Services, we may collect certain information automatically from your device. This information may
                      include internet protocol (IP) addresses, browser type, internet service provider (ISP),
                      referring/exit pages, operating system, date/time stamp, clickstream data, landing page, and
                      referring URL. To collect this information, a cookie may be set on your computer or device when
                      you visit our Services. Cookies contain a small amount of information that allows our web servers
                      to recognize you. We store information that we collect through cookies, log files, and/or clear
                      gifs to record your preferences. We may also automatically collect information about your use of
                      features of our Services, about the functionality of our Services, frequency of visits, and other
                      information related to your interactions with the Services. We may track your use across different
                      websites and services. In some countries, including countries in the European Economic Area
                      ("EEA"), the information referenced above in this paragraph may be considered personal information
                      under applicable data protection laws.
                    </li>

                    <li>
                      <strong>Usage of our Services.</strong> When you use our Services, we may collect information
                      about your engagement with and utilization of our Services, such as processor and memory usage,
                      storage capacity, navigation of our Services, and system-level metrics. We use this data to
                      operate the Services, maintain and improve the performance and utilization of the Services,
                      develop new features, protect the security and safety of our Services and our customers, and
                      provide customer support. We also use this data to develop aggregate analysis and business
                      intelligence that enable us to operate, protect, make informed decisions, and report on the
                      performance of our business.
                    </li>
                  </ol>
                </li>

                {/*
            <li>
              <strong>Information We Receive from Third Parties.</strong>

              <ol type="I">
                <li>
                  <strong>Third-Party Accounts.</strong> If you choose to link our Services to a third-party account, we
                  will receive information about that account, such as your authentication token from the third-party
                  account, to authorize linking. If you wish to limit the information available to us, you should visit
                  the privacy settings of your third-party accounts to learn about your options.
                </li>

                <li>
                  <strong>Third-Party Partners.</strong> We may also receive publicly available information about you from
                  our third-party partners and combine it with data that we have about you.
                </li>
              </ol>
            </li>
            */}
              </ol>

              <div data-slicetype="html" className="slice">
                <h2>2. How We Use Information</h2>

                <p>We use the information we collect in various ways, including to:</p>

                <ul>
                  <li>Provide, operate, and maintain our Services;</li>
                  <li>Improve, personalize, and expand our Services;</li>
                  <li>Understand and analyze how you use our Services;</li>
                  <li>Develop new products, services, features, and functionality;</li>
                  <li>
                    Communicate with you, either directly or through one of our partners, including for customer
                    service, to provide you with updates and other information relating to the Service, and for
                    marketing and promotional purposes;
                  </li>
                  <li>Process your transactions;</li>
                  <li>Send you text messages and push notifications;</li>
                  <li>Find and prevent fraud; and</li>
                  <li>
                    For compliance purposes, including enforcing our Terms of Service, or other legal rights, or as may
                    be required by applicable laws and regulations or requested by any judicial process or governmental
                    agency.
                  </li>
                </ul>
              </div>

              <h2 id="how-we-share-information">3. How We Share Information</h2>

              <p>We may share the information we collect in various ways, including the following:</p>

              <ol type="A">
                <li>
                  <strong>Vendors and Service Providers.</strong> We may share information with third-party vendors and
                  service providers that provide services on our behalf, such as helping to provide our Services, for
                  promotional and/or marketing purposes, and to provide you with information relevant to you such as
                  product announcements, software updates, special offers, or other information.
                </li>

                <li>
                  <strong>Aggregate Information.</strong> Where legally permissible, we may use and share information
                  about users with our partners in aggregated or de-identified form that can’t reasonably be used to
                  identify you.
                </li>

                <li>
                  <strong>Advertising.</strong> We work with third-party advertising partners to show you ads that we
                  think may interest you. These advertising partners may set and access their own cookies, pixel tags,
                  and similar technologies on our Services, and they may otherwise collect or have access to information
                  about you which they may collect over time and across different online services. Some of our
                  advertising partners are members of the Network Advertising Initiative or the Digital Advertising
                  Alliance. To learn more about these programs, or opt-out of personalized ads, visit the Digital
                  Advertising Alliance’s Self-Regulatory program for Online Behavioral Advertising at{' '}
                  <a href="http://www.aboutads.info">www.aboutads.info</a>, or the Network Advertising Initiative at{' '}
                  <a href="http://www.networkadvertising.org">www.networkadvertising.org</a>.
                </li>

                <li>
                  <strong>Third-Party Partners.</strong> We also share information about users with third-party partners
                  in order to receive additional publicly available information about you.
                </li>

                <li>
                  <strong>Information We Share When You Sign Up Through a Referral.</strong> If you sign up for our
                  Services through a referral from a friend, we may share information with your referrer to let them
                  know that you used their referral to sign up for our Services.
                </li>

                <li>
                  <strong>Analytics.</strong> We use analytics providers such as Google Analytics. Google Analytics uses
                  cookies to collect non-identifying information. Google provides some additional privacy options
                  regarding its Analytics cookies at{' '}
                  <a href="http://www.google.com/policies/privacy/partners/">
                    http://www.google.com/policies/privacy/partners/
                  </a>
                  .
                </li>

                <li>
                  <strong>Business Transfers.</strong> Information may be disclosed and otherwise transferred to any
                  potential acquirer, successor, or assignee as part of any proposed merger, acquisition, debt
                  financing, sale of assets, or similar transaction, or in the event of insolvency, bankruptcy, or
                  receivership in which information is transferred to one or more third parties as one of our business
                  assets.
                </li>

                <li>
                  <strong>As Required By Law and Similar Disclosures.</strong> We may also share information to (i)
                  satisfy any applicable law, regulation, legal process, or governmental request; (ii) enforce this
                  Privacy Policy and our Terms of Service, including investigation of potential violations hereof; (iii)
                  detect, prevent, or otherwise address fraud, security, or technical issues; (iv) respond to your
                  requests; or (v) protect our rights, property or safety, our users and the public. This includes
                  exchanging information with other companies and organizations for fraud protection and spam/malware
                  prevention.
                </li>

                <li>
                  <strong>With Your Consent.</strong> We may share information with your consent.
                </li>
              </ol>

              <div data-slicetype="html" className="slice">
                <h2>4. Legal Basis for Processing Personal Information</h2>

                <p>
                  Our legal basis for collecting and using the personal information described above will depend on the
                  personal information concerned and the specific context in which we collect it.
                </p>

                <p>
                  However, we will normally collect personal information from you only (i) where we need the personal
                  information to perform a contract with you; (ii) where the processing is in our legitimate interests
                  and not overridden by your rights; or (iii) where we have your consent to do so.&nbsp; We have a
                  legitimate interest in operating our Services and communicating with you as necessary to provide these
                  Services, for example when responding to your queries, improving our platform, undertaking marketing,
                  or for the purposes of detecting or preventing illegal activities.
                </p>

                <p>
                  In some cases, we may also have a legal obligation to collect personal information from you or may
                  otherwise need the personal information to protect your vital interests or those of another person.
                </p>

                <p>
                  If we ask you to provide personal information to comply with a legal requirement or to perform a
                  contract with you, we will make this clear at the relevant time and advise you whether the provision
                  of your personal information is mandatory or not (as well as of the possible consequences if you do
                  not provide your personal information).
                </p>

                <h2>5. Third-party Services</h2>

                <p>
                  You may access other third-party services through the Services, for example by clicking on links to
                  those third-party services from within the Services. We are not responsible for the privacy policies
                  and/or practices of these third-party services, and we encourage you to carefully review their privacy
                  policies.
                </p>

                <h2>6. Security</h2>

                <p>
                  AuthKeys is committed to protecting your information. To do so, we employ a variety of security
                  technologies and measures designed to protect information from unauthorized access, use, or
                  disclosure. The measures we use are designed to provide a level of security appropriate to the risk of
                  processing your personal information. However, please bear in mind that the Internet cannot be
                  guaranteed to be 100% secure.
                </p>

                <h2>7. Data Retention</h2>

                <p>
                  We retain personal information we collect from you where we have an ongoing legitimate business need
                  to do so (for example, to provide you with a service you have requested or to comply with applicable
                  legal, tax, or accounting requirements).
                </p>

                <p>
                  When we have no ongoing legitimate business need to process your personal information, we will either
                  delete or anonymize it or, if this is not possible (for example, because your personal information has
                  been stored in backup archives), then we will securely store your personal information and isolate it
                  from any further processing until deletion is possible.
                </p>

                <h2>8. Access</h2>

                <p>
                  If you are a registered user, you may access certain information associated with your Account by
                  logging into our Services or emailing&nbsp;
                  <a href="mailto:privacy@authkeys.io">privacy@authkeys.io</a>. If you terminate your Account, any
                  public activity on your Account prior to deletion may remain stored on our servers and may remain
                  accessible to the public.
                </p>

                <p>
                  To protect your privacy and security, we may also take reasonable steps to verify your identity before
                  updating or removing your information. The information you provide us may be archived or stored
                  periodically by us according to backup processes conducted in the ordinary course of business for
                  disaster recovery purposes. Your ability to access and correct your information may be temporarily
                  limited where access and correction could: inhibit AuthKeys's ability to comply with a legal
                  obligation; inhibit AuthKeys's ability to investigate, make or defend legal claims; result in
                  disclosure of personal information about a third party; or result in breach of a contract or
                  disclosure of trade secrets or other proprietary business information belonging to AuthKeys or a third
                  party.
                </p>

                <h2>9. Your Data Protection Rights Under the General Data Protection Regulation (GDPR)</h2>

                <p>If you are a resident of the EEA, you have the following data protection rights:</p>

                <ul>
                  <li>
                    If you wish to&nbsp;<strong>access, correct, update, or request deletion</strong>&nbsp;of your
                    personal information, you can do so at any time by emailing&nbsp;
                    <a href="mailto:privacy@authkeys.io">privacy@authkeys.io</a>.
                  </li>
                  <li>
                    In addition, you can&nbsp;<strong>object to the processing</strong>&nbsp;of your personal
                    information, ask us to&nbsp;<strong>restrict the processing</strong>&nbsp;of your personal
                    information, or&nbsp;
                    <strong>request portability</strong>&nbsp;of your personal information. Again, you can exercise
                    these rights by emailing&nbsp;<a href="mailto:privacy@authkeys.io">privacy@authkeys.io</a>.
                  </li>
                  <li>
                    You have the right to&nbsp;<strong>opt-out of marketing communications</strong>&nbsp;we send you at
                    any time. You can exercise this right by clicking on the "unsubscribe" or "opt-out" link in the
                    marketing emails we send you.&nbsp;To opt-out of other forms of marketing, please contact us by
                    emailing&nbsp;
                    <a href="mailto:privacy@authkeys.io">privacy@authkeys.io</a>.
                  </li>
                  <li>
                    Similarly, if we have collected and process your personal information with your consent, then you
                    can
                    <strong>withdraw your consent</strong>&nbsp;at any time.&nbsp; Withdrawing your consent will not
                    affect the lawfulness of any processing we conducted prior to your withdrawal, nor will it affect
                    the processing of your personal information conducted in reliance on lawful processing grounds other
                    than consent.
                  </li>
                  <li>
                    You have the&nbsp;<strong>right to complain to a data protection authority</strong>&nbsp;about our
                    collection and use of your personal information. For more information, please contact your local
                    data protection authority.
                  </li>
                </ul>

                <p>
                  We respond to all requests we receive from individuals wishing to exercise their data protection
                  rights in accordance with applicable data protection laws.
                </p>

                <h2>10. Your Choices</h2>

                <p>
                  You can use some of the features of the Services without registering, thereby limiting the type of
                  information that we collect.
                </p>

                <p>
                  You may unsubscribe from receiving certain promotional emails from us. If you wish to do so, simply
                  follow the instructions found at the end of the email. Even if you unsubscribe, we may still contact
                  you for informational, transactional, account-related, or similar purposes.
                </p>

                <p>
                  Many browsers have an option for disabling cookies, which may prevent your browser from accepting new
                  cookies or enable selective use of cookies. Please note that, if you choose not to accept cookies,
                  some features and the personalization of our Services may no longer work for you. You will continue to
                  receive advertising material but it will not be tailored to your interests.
                </p>

                <h2>11. Children's Privacy</h2>

                <p>
                  AuthKeys does not knowingly collect information from children under the age of 13, and children under
                  13 are prohibited from using our Services. If you learn that a child has provided us with personal
                  information in violation of this Privacy Policy, you can alert us at&nbsp;
                  <a href="mailto:privacy@authkeys.io">privacy@authkeys.io</a>.
                </p>

                <h2>12. Changes to this Privacy Policy</h2>

                <p>
                  This Privacy Policy may be modified from time to time, so please review it frequently. Changes to this
                  Privacy Policy will be posted on our websites. If we materially change the ways in which we use or
                  share personal information previously collected from you through our Services, we will notify you
                  through our Services, by email, or other communication.
                </p>

                <h2>Contact Us</h2>

                <p>
                  If you have any questions or concerns about this Privacy Policy, please feel free to email us at{' '}
                  <a href="mailto:privacy@authkeys.io">privacy@authkeys.io</a>.
                </p>

                <p>The data controller of your personal information is Fluidware srl.</p>
              </div>

              <div className="row mt-5">
                <div className="col-12 text-center">
                  <Link to="/contact-us" className="btn btn-lg btn-outline-primary p-3" style={{ fontSize: '1.2em' }}>
                    If you have any questions, contact us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    setDocumentTitle('Privacy Policy');
  }
}

export default PrivacyPolicy;
