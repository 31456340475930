import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Logo from '../../assets/images/ak-logo.svg';
import { getConsoleUrl } from '../../lib/utils/siteUtils';

const links = [
  {
    to: '/features',
    title: 'Features'
  },
  {
    to: '/how-it-works',
    title: 'How it works'
  },
  {
    to: '/security',
    title: 'Security'
  },
  {
    to: '/pricing',
    title: 'Pricing'
  },
  {
    to: '/contact-us',
    title: 'Contact us'
  }
];
class TopMenu extends Component {
  static propTypes = {
    location: PropTypes.object
  };

  render() {
    const { location } = this.props;
    const AK_CONSOLE_URL = getConsoleUrl();
    return (
      <div className="bg-light d-flex flex-column flex-md-row align-items-center p-3 px-md-4">
        <Link to="/" className="my-0 mr-md-auto text-muted">
          <img src={Logo} height={50} alt="Logo" title="AuthKeys" />
        </Link>
        <nav className="my-2 my-md-0 mr-md-2">
          <div className="d-md-flex flex-md-row">
            {links.map((item, i) => {
              return this.getMenuItem(item, i, location.pathname);
            })}
          </div>
        </nav>
        <span>
          <a href={`${AK_CONSOLE_URL}/signin`} className="btn btn-outline-primary m-1">
            Sign in
          </a>
          <a href={`${AK_CONSOLE_URL}/signup`} className="btn btn-primary m-1">
            Sign up
          </a>
        </span>
      </div>
    );
  }

  getMenuItem = (item, pos, pathname) => {
    return (
      <Link key={pos} to={item.to} className={`p-2 text-primary ${item.to === pathname ? 'font-weight-bold' : ''}`}>
        <span className="text-nowrap ">{item.title}</span>
      </Link>
    );
  };
}

export default withRouter(TopMenu);
