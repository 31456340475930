import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { setDocumentTitle } from '../../../lib/utils/siteUtils';

class Terms extends Component {
  constructor(props) {
    super(props);
    setDocumentTitle('Terms and Conditions');
  }
  render() {
    return (
      <div className="legal-wrapper">
        <div className="p-3 bg-primary text-light text-center">
          <h1 className="mb-0">Terms and conditions</h1>
        </div>

        <div className="container-fluid text-primary bg-light py-5">
          <div className="row mb-5 justify-content-md-center">
            <div className="col-lg-5 col-md-7 col-sm-12">
              <p>Last updated: May 23, 2020</p>

              <p>
                These Terms and Conditions ("Terms", "Terms and Conditions") govern your relationship with
                https://authkeys.io website (the "Service") operated by Fluidware srl ("us", "we", or "our").
              </p>

              <p>Please read these Terms and Conditions carefully before using the Service.</p>

              <p>
                Your access to and use of the Service is conditioned on your acceptance of and compliance with these
                Terms. These Terms apply to all visitors, users and others who access or use the Service.
              </p>

              <p>
                By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of
                the terms then you may not access the Service.
              </p>

              <h4>Subscriptions</h4>

              <p>
                Some parts of the Service are billed on a subscription basis ("Subscription(s)"). You will be billed in
                advance on a recurring and periodic basis ("Billing Cycle"). Billing cycles are set either on a monthly
                or annual basis, depending on the type of subscription plan you select when purchasing a Subscription.
              </p>

              <p>
                At the end of each Billing Cycle, your Subscription will automatically renew under the exact same
                conditions unless you cancel it or Fluidware srl cancels it. You may cancel your Subscription renewal
                either through your online account management page or by contacting Fluidware srl customer support team.
              </p>

              <p>
                A valid payment method, including credit card, is required to process the payment for your Subscription.
                You shall provide Fluidware srl with accurate and complete billing information including full name,
                address, state, zip code, telephone number, and a valid payment method information. By submitting such
                payment information, you automatically authorize Fluidware srl to charge all Subscription fees incurred
                through your account to any such payment instruments.
              </p>

              <p>
                Should automatic billing fail to occur for any reason, Fluidware srl will issue an electronic invoice
                indicating that you must proceed manually, within a certain deadline date, with the full payment
                corresponding to the billing period as indicated on the invoice.
              </p>

              <h4>Fee Changes</h4>

              <p>
                Fluidware srl, in its sole discretion and at any time, may modify the Subscription fees for the
                Subscriptions. Any Subscription fee change will become effective at the end of the then-current Billing
                Cycle.
              </p>

              <p>
                Fluidware srl will provide you with a reasonable prior notice of any change in Subscription fees to give
                you an opportunity to terminate your Subscription before such change becomes effective.
              </p>

              <p>
                Your continued use of the Service after the Subscription fee change comes into effect constitutes your
                agreement to pay the modified Subscription fee amount.
              </p>

              <h4>Refunds</h4>

              <p>Except when required by law, paid Subscription fees are non-refundable.</p>

              <h4>Accounts</h4>

              <p>
                When you create an account with us, you must provide us information that is accurate, complete, and
                current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate
                termination of your account on our Service.
              </p>

              <p>
                You are responsible for safeguarding the password that you use to access the Service and for any
                activities or actions under your password, whether your password is with our Service or a third-party
                service.
              </p>

              <p>
                You agree not to disclose your password to any third party. You must notify us immediately upon becoming
                aware of any breach of security or unauthorized use of your account.
              </p>

              <p>
                You may not use as instance name the name of another person or entity or that is not lawfully available
                for use, a name or trade mark that is subject to any rights of another person or entity other than you
                without appropriate authorization, or a name that is otherwise offensive, vulgar or obscene.
              </p>

              <h4>Intellectual Property</h4>

              <p>
                The Service and its original content, features and functionality are and will remain the exclusive
                property of Fluidware srl and its licensors. The Service is protected by copyright, trademark, and other
                laws of both the Italian and foreign countries. Our trademarks and trade dress may not be used in
                connection with any product or service without the prior written consent of Fluidware srl.
              </p>

              <h4>Links To Other Web Sites</h4>

              <p>
                Our Service may contain links to third-party web sites or services that are not owned or controlled by
                Fluidware srl.
              </p>

              <p>
                Fluidware srl has no control over, and assumes no responsibility for, the content, privacy policies, or
                practices of any third party web sites or services. You further acknowledge and agree that Fluidware srl
                shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to
                be caused by or in connection with use of or reliance on any such content, goods or services available
                on or through any such web sites or services.
              </p>

              <p>
                We strongly advise you to read the terms and conditions and privacy policies of any third-party web
                sites or services that you visit.
              </p>

              <h4>Termination</h4>

              <p>
                We may terminate or suspend your account immediately, without prior notice or liability, for any reason
                whatsoever, including without limitation if you breach the Terms.
              </p>

              <p>
                Upon termination, your right to use the Service will immediately cease. If you wish to terminate your
                account, you must delete your account from the web console.
              </p>

              <h4>Limitation Of Liability</h4>

              <p>
                In no event shall Fluidware srl, nor its directors, employees, partners, agents, suppliers, or
                affiliates, be liable for any indirect, incidental, special, consequential or punitive damages,
                including without limitation, loss of profits, data, use, goodwill, or other intangible losses,
                resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct
                or content of any third party on the Service; (iii) any content obtained from the Service; and (iv)
                unauthorized access, use or alteration of your transmissions or content, whether based on warranty,
                contract, tort (including negligence) or any other legal theory, whether or not we have been informed of
                the possibility of such damage, and even if a remedy set forth herein is found to have failed of its
                essential purpose.
              </p>

              <h4>Disclaimer</h4>

              <p>
                Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE"
                basis. The Service is provided without warranties of any kind, whether express or implied, including,
                but not limited to, implied warranties of merchantability, fitness for a particular purpose,
                non-infringement or course of performance.
              </p>

              <p>
                Fluidware srl its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will
                function uninterrupted, secure or available at any particular time or location; b) any errors or defects
                will be corrected; c) the Service is free of viruses or other harmful components; or d) the results of
                using the Service will meet your requirements.
              </p>

              <h4>Governing Law</h4>

              <p>
                These Terms shall be governed and construed in accordance with the laws of Italy, without regard to its
                conflict of law provisions.
              </p>

              <p>
                Our failure to enforce any right or provision of these Terms will not be considered a waiver of those
                rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining
                provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us
                regarding our Service, and supersede and replace any prior agreements we might have between us regarding
                the Service.
              </p>

              <h4>Changes</h4>

              <p>
                We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a
                revision is material we will try to provide at least 30 days notice prior to any new terms taking
                effect. What constitutes a material change will be determined at our sole discretion.
              </p>

              <p>
                By continuing to access or use our Service after those revisions become effective, you agree to be bound
                by the revised terms. If you do not agree to the new terms, please stop using the Service.
              </p>
              <div className="row mt-5">
                <div className="col-12 text-center">
                  <Link to="/contact-us" className="btn btn-lg btn-outline-primary p-3" style={{ fontSize: '1.2em' }}>
                    If you have any questions, contact us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Terms;
