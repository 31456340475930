import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getConsoleUrl, setDocumentTitle } from '../../lib/utils/siteUtils';
import Logo from '../../assets/images/ak-logo-vertical.svg';
import HomePricingBlock from '../Pricing/HomePricingBlock';
import Tour from '../Tour';

class Home extends Component {
  render() {
    const console = getConsoleUrl();
    if (!console) {
      return (
        <div className="App">
          <div style={{ marginTop: -24 }} className="bg-light text-center py-3">
            <img src={Logo} alt="AuthKeys" width={300} title="AuthKeys" className="img-fluid" />
          </div>
          <div className="position-relative overflow-hidden p-3 m-md-3 text-center">
            <div>Loading...</div>
          </div>
        </div>
      );
    }
    return (
      <div className="App bg-primary ">
        <div style={{ marginTop: -24 }} className="bg-light text-center py-3">
          <img src={Logo} alt="AuthKeys" width={300} title="AuthKeys" className="img-fluid" />
        </div>
        <div className="container py-5">
          <div className="text-light" style={{ fontSize: '1.8em' }}>
            <div className="row">
              <div className="col-12 text-center">
                <h1 className="display-4 font-weight-normal">
                  <span>SSH keys</span> <span>authorization</span> <span>as a service</span>
                </h1>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12 col-lg-8 offset-lg-2">
                <p className="px-3 text-center">
                  AuthKeys brings SSH public keys authentication to the next level, with a modern SaaS platform to
                  manage and audit your servers authorizations and logins
                </p>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12 offset-xl-1 col-xl-5 col-lg-6 text-lg-right mb-2">
                <a href="#plans" className="btn btn-lg btn-outline-light p-3 w-100" style={{ fontSize: '1.0em' }}>
                  Plans for all team sizes
                </a>
              </div>

              <div className="col-12 col-xl-5 col-lg-6 mb-2">
                <Link
                  to="/how-it-works"
                  className="btn btn-lg btn-outline-light p-3 w-100"
                  style={{ fontSize: '1.0em' }}
                >
                  See how AuthKeys works
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-light text-primary p-lg-5 pb-5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3 col-md-6 mt-3">
                <div className="card border-primary p-4" style={{ height: '100%' }}>
                  <h2>1. Configure your servers</h2>
                  <p style={{ fontSize: '1.2em' }} className="mt-3">
                    Install the AuthKeys agent in your servers. The agent will retrieve and verify authorized public SSH
                    keys.
                  </p>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 mt-3">
                <div className="card border-primary p-4" style={{ height: '100%' }}>
                  <h2>2. Add public SSH keys</h2>
                  <p style={{ fontSize: '1.2em' }} className="mt-3">
                    Every public SSH key you add will be signed with the RSA certificate of your AuthKeys instance.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mt-3">
                <div className="card border-primary p-4" style={{ height: '100%' }}>
                  <h2>3. Grant access permissions</h2>
                  <p style={{ fontSize: '1.2em' }} className="mt-3">
                    You decide which servers each public SSH key owner can access, and with which server user.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mt-3">
                <div className="card border-primary p-4" style={{ height: '100%' }}>
                  <h2>4. Verify access requests</h2>
                  <p style={{ fontSize: '1.2em' }} className="mt-3">
                    The AuthKeys agent will verify public SSH key signatures using the RSA certificate of your AuthKeys
                    instance.
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-12 text-center">
                <Link to="/how-it-works" className="btn btn-lg btn-outline-primary p-3" style={{ fontSize: '1.2em' }}>
                  Read more about how AuthKeys works
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="text-center p-3 text-light" id="plans">
          <h1 className="mb-0">Plans for all teams</h1>
        </div>

        <div className="bg-light text-primary">
          <HomePricingBlock />
        </div>

        <div className="text-center p-3 text-light">
          <h1 className="mb-0">Get a preview</h1>
        </div>

        <div className="bg-light text-primary">
          <Tour />
        </div>
      </div>
    );
  }

  componentDidMount() {
    setDocumentTitle('Home');
  }
}

export default Home;
