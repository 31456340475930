import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './components/Home';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import store, { history } from './store';
import './assets/ak.scss';

import TopMenu from './components/TopMenu';

import CookieConsent from './components/CookieConsent';
import { COOKIE_CONSENT_ACCEPTED, getCookie } from './lib/utils/cookiesutils';
import Legal from './components/Legal';
import Pricing from './components/Pricing';
import Footer from './components/Footer';

import loadGtag from './lib/utils/loadGtag';
import { GTAG_SITE_KEY, setInfo } from './lib/utils/siteUtils';
import Team from './components/Team';
import GoogleAnalytics from './components/Common/GoogleAnalytics';
import Contact from './components/Contact';
import Security from './components/Security';
import Docs from './components/Docs';
import HowItWorksPage from './components/HowItWorks';
import ScrollToTop from './components/Common/ScrollToTop';
import NotFound from './components/Common/NotFound';
import { Features } from './components/Features';

class BaseApp extends Component {
  constructor(props) {
    super(props);
    loadGtag(GTAG_SITE_KEY);
  }

  state = {
    loaded: false
  };

  render() {
    const { loaded } = this.state;
    if (!loaded) {
      return <div>Loading...</div>;
    }
    const cookieconsent_accepted = getCookie(COOKIE_CONSENT_ACCEPTED);
    const showCookieConsent = !cookieconsent_accepted || cookieconsent_accepted !== '1';
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Router>
            <div className="page-content">
              <GoogleAnalytics>
                <ScrollToTop>
                  <TopMenu />
                  <div className="page-content-wrapper">
                    <Switch>
                      <Route exact path="/features" component={Features} />
                      <Route exact path="/contact-us" component={Contact} />
                      <Route exact path="/company" component={Team} />
                      <Route exact path="/pricing" component={Pricing} />
                      <Route exact path="/how-it-works" component={HowItWorksPage} />
                      <Route path="/security" component={Security} />
                      <Route path="/legal" component={Legal} />
                      <Route path="/docs" component={Docs} />
                      <Route exact path="/" component={Home} />
                      <Route path="/" component={NotFound} />
                    </Switch>
                  </div>
                  <Footer />
                  {showCookieConsent && <CookieConsent />}
                </ScrollToTop>
              </GoogleAnalytics>
            </div>
          </Router>
        </ConnectedRouter>
      </Provider>
    );
  }

  componentDidMount() {
    fetch('/api/system', {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    })
      .then(res => {
        if (!res.ok) {
          throw Error(res.statusText);
        }
        return res.json();
      })
      .then(data => {
        setInfo(data);
        this.setState({ loaded: true });
      })
      .catch(err => {
        console.error(err);
      });
  }
}

ReactDOM.render(<BaseApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
