import React, { Component } from 'react';

const videos = [
  {
    title: 'Signing up',
    vid: 'UgNi2jW_3iY'
  },
  {
    title: 'Setting up',
    vid: 'cfFVZa68alY'
  },
  {
    title: 'Adding public keys',
    vid: 'X50Tx34eeX4'
  },
  {
    title: 'SSH with Cloud-init',
    vid: '6-Cf1J8ukYA'
  }
];
class Tour extends Component {
  state = {
    open: -1
  };
  render() {
    const { open } = this.state;
    return (
      <div className="container-fluid">
        <div className="row">
          {videos.map((video, i) => {
            return (
              <div className="col-xl-3 col-md-6 col-12 my-5" key={i}>
                <h2>{video.title}</h2>

                {this.state.open !== i && (
                  <button
                    type="button"
                    className="btn btn-link video-link p-0"
                    onClick={e => this.setState({ open: i })}
                  >
                    <img
                      className="img-fluid"
                      src={`https://img.youtube.com/vi/${video.vid}/0.jpg`}
                      alt={video.title}
                    />
                  </button>
                )}
              </div>
            );
          })}
        </div>
        {open >= 0 && (
          <div>
            <div className="modal fade show" style={{ display: 'block' }}>
              <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">{videos[open].title}</h5>
                    <button type="button" className="close" aria-label="Close Modal" onClick={this.handleClose}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body text-center">
                    <div className="embed-responsive embed-responsive-16by9">
                      <iframe
                        title={videos[open].title}
                        src={'https://www.youtube.com/embed/' + videos[open].vid + '?autoplay=1'}
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-backdrop fade show" />
          </div>
        )}
      </div>
    );
  }

  handleClose = () => {
    this.setState({ open: -1 });
  };
}

export default Tour;
