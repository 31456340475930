import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getConsoleUrl } from '../../lib/utils/siteUtils';

class Footer extends Component {
  render() {
    const year = new Date().getFullYear();
    const AK_CONSOLE_URL = getConsoleUrl();
    return (
      <div className="bg-light">
        <div className="container">
          <footer className="pt-4 pt-md-5">
            <div className="row">
              <div className="col-12 col-md text-center">
                <Link to="/">
                  <img
                    className="mb-3"
                    src="/assets/brand/ak128.png"
                    alt="AK Logo"
                    width="64"
                    height="64"
                    title="AuthKeys"
                  />
                </Link>
                <small className="d-block mb-3 text-muted">© 2018-{year} Fluidware srl</small>
              </div>
              <div className="col-6 col-md">
                <h5>Resources</h5>
                <ul className="list-unstyled text-small">
                  <li>
                    <Link to="/how-it-works" className="text-muted">
                      How it works
                    </Link>
                  </li>
                  <li>
                    <Link to="/security" className="text-muted">
                      Security
                    </Link>
                  </li>
                  <li>
                    <Link to="/pricing" className="text-muted">
                      Pricing
                    </Link>
                  </li>
                  {/*<li>
                  <Link to="/features" className="text-muted">
                    Features
                  </Link>
                </li>
                <li>
                  <Link to="/tour" className="text-muted">
                    Tour
                  </Link>
                </li>
                */}
                </ul>
              </div>
              <div className="col-6 col-md">
                <h5>About</h5>
                <ul className="list-unstyled text-small">
                  {/*
                <li>
                  <Link to="/company" className="text-muted">
                    Company
                  </Link>
                </li>
                <li>
                  <Link to="/team" className="text-muted">
                    Team
                  </Link>
                </li>
                */}
                  <li>
                    <Link to="/legal/terms" className="text-muted">
                      Terms and conditions
                    </Link>
                  </li>
                  <li>
                    <Link to="/legal/privacy-policy" className="text-muted">
                      Privacy policy
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact-us" className="text-muted">
                      Contact us
                    </Link>
                  </li>
                  <li>
                    <a href={`${AK_CONSOLE_URL}/signin`} className="text-muted">
                      Sign in
                    </a>
                  </li>
                  <li>
                    <a href={`${AK_CONSOLE_URL}/signup`} className="text-muted">
                      Sign up
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-md">
                <h5>Company</h5>
                <span className="d-block mb-3 text-muted small">
                  <p>
                    <strong>Fluidware Srl</strong>
                    <br />
                    Via Mantova 17
                    <br />
                    20135, Milano (MI) <br />
                    Italy
                  </p>
                  <p>
                    <strong>VAT</strong>: IT 07524170961 <br />
                  </p>
                </span>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default Footer;
