export const GTAG_SITE_KEY = 'UA-131552756-1';
export const setDocumentTitle = function(title) {
  document.title = `${title} :: AuthKeys`;
};

let consoleUrl;
let helpCenter = true;
let trialDays = 0;

export const getTrialDays = function() {
  return trialDays;
};

export const getConsoleUrl = function() {
  return consoleUrl;
};

export const setConsoleUrl = function(url) {
  consoleUrl = url;
};

export const getHelpCenter = function() {
  return helpCenter;
};

export const setHelpCenter = function(status) {
  consoleUrl = status;
};

export const setInfo = function({ console, hc, trial_days }) {
  consoleUrl = console;
  helpCenter = hc;
  trialDays = trial_days;
};
