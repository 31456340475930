import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { setDocumentTitle } from '../../lib/utils/siteUtils';

class LegalHome extends Component {
  constructor(props) {
    super(props);
    setDocumentTitle('Legal');
  }

  render() {
    return (
      <div className="container rounded-left rounded-right bg-light">
        <div className="p-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item active" aria-current="page">
              Legal
            </li>
          </ol>

          <h1 className="text-primary">Legal</h1>
          <ul className="list-group">
            <li className="list-group-item">
              <Link to="/legal/terms">Terms and conditions</Link>
            </li>
            <li className="list-group-item">
              <Link to="/legal/privacy-policy">Privacy Policy</Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  componentDidMount() {
    setDocumentTitle('Legal');
  }
}

export default LegalHome;
