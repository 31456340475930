import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

function modules(history) {
  return combineReducers({
    router: connectRouter(history)
  });
}

export default modules;
