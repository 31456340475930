import React from 'react';

export const DoodleLibraryDisclaymer = function() {
  return (
    <div className="row pt-5 text-center justify-content-center">
      <small>
        Illustration based on{' '}
        <a href="https://www.thedoodlelibrary.com/" target="_blank" rel="noopener noreferrer">
          The Doodle Library
        </a>{' '}
        -
        <a
          className="ml-1"
          href="https://creativecommons.org/licenses/by/4.0/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Creative Commons Attribution 4.0 International License
        </a>
      </small>
    </div>
  );
};
