import React, { Component } from 'react';
import { setDocumentTitle } from '../../lib/utils/siteUtils';
import { ReactComponent as Icon1 } from './1.svg';
import { ReactComponent as Icon2 } from './2.svg';
import { ReactComponent as Icon3 } from './3.svg';
import { ReactComponent as Icon4 } from './4.svg';
import { Link } from 'react-router-dom';
import { DoodleLibraryDisclaymer } from '../Common/DoodleLibraryDisclaimer';

class Security extends Component {
  render() {
    return (
      <div className="security-wrapper">
        <div className="p-3 bg-primary text-light text-center">
          <h1 className="mb-0">AuthKeys security</h1>
        </div>
        <div className="bg-light text-primary py-5">
          <div className="container-fluid">
            <div className="row mb-5">
              <div className="col-12">
                <h1>How AuthKeys keeps your servers protected</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-6 col-lg-3 mb-5 mb-sm-4">
                <div className="mb-3 px-5 px-lg-0">
                  <Icon1 />
                </div>
                Your AuthKeys instance is configured with your very own RSA certificate: the "admin cert".
              </div>
              <div className="col-12 col-sm-6 col-lg-3 mb-5 mb-sm-4">
                <div className="mb-3 px-5 px-lg-0">
                  <Icon2 />
                </div>
                Your admin cert is generated and encrypted in your own workstation. AuthKeys only ever stores the
                encrypted admin cert.
              </div>
              <div className="col-12 col-sm-6 col-lg-3 mb-5 mb-sm-4">
                <div className="mb-3 px-5 px-lg-0">
                  <Icon3 />
                </div>
                All public SSH keys are signed with your admin cert. The AuthKeys agent will deny access to SSH keys
                unsigned or with an invalid signature.
              </div>
              <div className="col-12 col-sm-6 col-lg-3 mb-5 mb-sm-4">
                <div className="mb-3 px-5 px-lg-0">
                  <Icon4 />
                </div>
                All accesses to your servers and all operations are logged and archived. You can retrive accesses and
                audits logs within the web console.
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-12 text-center">
                <Link to="/contact-us" className="btn btn-lg btn-outline-primary p-3" style={{ fontSize: '1.2em' }}>
                  Questions about security? Get in touch
                </Link>
              </div>
            </div>
            <DoodleLibraryDisclaymer />
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    setDocumentTitle('Security');
  }
}

export default Security;
