import React, { Component } from 'react';
import { getConsoleUrl, getTrialDays } from '../../lib/utils/siteUtils';
import { Link } from 'react-router-dom';

class HomePricingBlock extends Component {
  state = {
    plans: false
  };

  render() {
    const { plans } = this.state;
    const console = getConsoleUrl();
    const trialDays = getTrialDays();
    if (!console || !plans) {
      return (
        <div className="position-relative overflow-hidden p-3 m-md-3 text-center">
          <div>Loading...</div>
        </div>
      );
    }
    return (
      <div className="container home-plans-wrapper py-4 pb-5">
        <div className="row text-center">
          {plans.f && (
            <div className="col-lg-3 col-md-6 mt-3">
              <div className="card border-primary " style={{ height: '100%', padding: '24px 24px 80px' }}>
                <h2>{plans.f.name}</h2>
                <div className="quantity">
                  <span className="dollar">$</span>
                  <span className="price">0</span>
                </div>
                {this.getPlanDetail(plans.f, trialDays)}
                <a href={`${console}/signup`} className="btn btn-lg btn-primary mt-4">
                  Sign up
                </a>
              </div>
            </div>
          )}
          {plans.s && (
            <div className="col-lg-3 col-md-6 mt-3">
              <div className="card border-primary " style={{ height: '100%', padding: '24px 24px 80px' }}>
                <h2>{plans.s.name}</h2>
                <div className="quantity">
                  <span className="dollar">$</span>
                  <span className="price">{plans.s.options.monthly_cost / 100}</span>
                </div>
                <div className="period">per month</div>
                {this.getPlanDetail(plans.s, trialDays)}
                <a href={`${console}/signup?plan=silver`} className="btn btn-lg btn-primary mt-4">
                  Sign up
                </a>
              </div>
            </div>
          )}
          <div className="col-lg-3 col-md-6 mt-3">
            <div className="card border-primary " style={{ height: '100%', padding: '24px 24px 80px' }}>
              <h2>{plans.g.name}</h2>
              <div className="quantity">
                <span className="dollar">$</span>
                <span className="price">{plans.g.options.monthly_cost / 100}</span>
              </div>
              <div className="period">per month</div>
              {this.getPlanDetail(plans.g, trialDays)}
              <a href={`${console}/signup?plan=gold`} className="btn btn-lg btn-primary mt-4">
                Sign up
              </a>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mt-3">
            <div className="card border-primary " style={{ height: '100%', padding: '24px 24px 80px' }}>
              <h2>{plans.p.name}</h2>
              <div className="quantity">
                <span className="dollar">$</span>
                <span className="price">{plans.p.options.monthly_cost / 100}</span>
              </div>
              <div className="period">per month</div>
              {this.getPlanDetail(plans.p, trialDays)}
              <a href={`${console}/signup?plan=platinum`} className="btn btn-lg btn-primary mt-4">
                Sign up
              </a>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mt-3">
            <div className="card border-primary " style={{ height: '100%', padding: '24px 24px 80px' }}>
              <h2>Enterprise</h2>
              <p className="mt-5">
                If you need something different, <u>tell us about it.</u>
              </p>
              <p>We offer custom plans for your enterprise needs.</p>
              <Link to="/contact-us" className="btn btn-lg btn-primary mt-4">
                Contact us
              </Link>
            </div>
          </div>
        </div>
        {trialDays > 100 && (
          <div className="row text-center">
            <div className="col mt-3">
              <div className="alert alert-secondary border border-primary" style={{ fontSize: '1.2em' }}>
                Try AuthKeys free for {trialDays} days!
              </div>
            </div>
          </div>
        )}
        <div className="row mt-3">
          <div className="col-12 col-xl-10 offset-xl-1 text-center">
            <Link to="/pricing" className="btn btn-lg btn-outline-primary py-3" style={{ fontSize: '1.2em' }}>
              See full pricing information
            </Link>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    fetch('/api/plans', {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    })
      .then(res => {
        if (!res.ok) {
          throw Error(res.statusText);
        }
        return res.json();
      })
      .then(data => {
        this.setState({ plans: data });
      })
      .catch(err => {
        console.error(err);
      });
  }

  getPlanDetail = (plan, trialDays) => {
    return (
      <div className="list-group list-group-flush mt-3">
        {trialDays > 0 && (
          <div className="ribbon ribbon-blue-mms">
            <div className="banner">
              <div className="text">{trialDays} Days Free Trial</div>
            </div>
          </div>
        )}
        <div className="list-group-item">
          {plan.options.team_members} AuthKeys account{plan.options.team_members > 1 && 's'}
        </div>
        <div className="list-group-item">
          {plan.options.ag_limit < 0 ? 'unlimited' : plan.options.ag_limit} key owners
        </div>
        <div className="list-group-item">{plan.options.api_calls / 1000}k API calls</div>
      </div>
    );
  };
}

export default HomePricingBlock;
