import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './cookieConsent.css';
import { COOKIE_CONSENT_ACCEPTED, setCookie } from '../../lib/utils/cookiesutils';

class CookieConsent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accepted: false,
      hide: false
    };
  }

  render() {
    const { accepted, hide } = this.state;
    if (hide) return null;
    return (
      <div className={'_panel_1nv7e_1' + (accepted ? ' _fadeout_1nv7e_12' : '')}>
        <div className="_wrap_1nv7e_16">
          <p className="_text_1nv7e_24">
            We use cookies to provide our services and for analytics and marketing. To find out more about our use of
            cookies, please see our{' '}
            <Link className="_link_1nv7e_33" target="_blank" tabIndex="0" to="/legal/privacy-policy/">
              Privacy Policy
            </Link>
            . By continuing to browse our website, you agree to our use of&nbsp;cookies.
          </p>
          <button className="_button_1nv7e_39" onClick={this.onAccept}>
            OK
          </button>
        </div>
      </div>
    );
  }

  onAccept = e => {
    e.preventDefault();
    this.setState({ accepted: true }, () => {
      setCookie(COOKIE_CONSENT_ACCEPTED, 1, 365 * 5);
      setTimeout(() => {
        this.setState({ hide: true });
      }, 1200);
    });
  };
}

export default CookieConsent;
