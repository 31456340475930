import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import PrivacyPolicy from './PrivacyPolicy';
import LegalHome from './LegalHome';
import Terms from './Terms';

class Legal extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired
  };

  render() {
    const { match } = this.props;
    return (
      <section className="legal">
        <Route path={`${match.url}/privacy-policy`} component={PrivacyPolicy} />
        <Route path={`${match.url}/terms`} component={Terms} />
        <Route exact path={match.url} component={LegalHome} />
      </section>
    );
  }
}

export default Legal;
