import React, { Component } from 'react';
import { getConsoleUrl, getHelpCenter, getTrialDays } from '../../lib/utils/siteUtils';
import { Link } from 'react-router-dom';
import { thousands_separators } from '../../lib/utils/stringUtils';

class PricingBlock extends Component {
  state = {
    plans: false
  };

  render() {
    const { plans } = this.state;
    const console = getConsoleUrl();
    if (!console || !plans) {
      return (
        <div className="position-relative overflow-hidden p-3 m-md-3 text-center">
          <div>Loading...</div>
        </div>
      );
    }
    const firstPlan = plans.s ? 's' : 'f';
    const trialDays = getTrialDays();
    return (
      <div id="plans">
        {trialDays > 0 && (
          <div className="mb-4 text-center">
            <div className="alert alert-secondary" style={{ fontSize: '1.2em' }}>
              Try AuthKeys free for {trialDays} days!
            </div>
          </div>
        )}
        <div style={{ maxWidth: '100%', overflow: 'auto' }}>
          <table className="table table-bordered table-plans">
            <colgroup>
              <col style={{ minWidth: 300, width: '25%' }} />
              <col style={{ minWidth: 300, width: '20%' }} />
              <col style={{ minWidth: 300, width: '20%' }} />
              <col style={{ minWidth: 300, width: '20%' }} />
              <col width="15%" />
            </colgroup>
            <tbody className="text-muted">
              <tr className="text-primary">
                <th />
                {Object.keys(plans).map((p, i) => {
                  return (
                    <th key={i} className="text-center">
                      <h2>{plans[p].name}</h2>
                    </th>
                  );
                })}
                <th className="d-none d-lg-table-cell text-center">
                  <h2>Enterprise</h2>
                </th>
              </tr>
              <tr>
                <th className="align-middle">
                  <h5>Price</h5>
                  <span className="badge badge-primary">2 months free if you pay annually</span>
                  <p className="small text-muted mt-2">
                    Payment via bank card. We accept Visa, Mastercard and American Express.
                  </p>
                </th>
                <td className="text-muted text-center align-middle">
                  <h1 className="text-muted">$ {plans[firstPlan].options.monthly_cost / 100}</h1>
                  per month
                </td>
                <td className="text-muted text-center align-middle">
                  <h1 className="text-muted">$ {plans.g.options.monthly_cost / 100}</h1>
                  per month
                </td>
                <td className="text-muted text-center align-middle">
                  <h1 className="text-muted">$ {plans.p.options.monthly_cost / 100}</h1>
                  per month
                </td>
                <td rowSpan="8" className="d-none d-lg-table-cell">
                  <div className="alert alert-info">
                    We can offer you
                    <br /> a custom plan if <br />
                    the standard plans don't
                    <br />
                    fit your needs.
                  </div>
                </td>
              </tr>
              <tr>
                <th className="align-middle">
                  <h5>AuthKeys accounts</h5>
                  <p className="small text-muted">Number of people who can access the AuthKeys web console.</p>
                </th>
                <td className="text-muted text-center align-middle">
                  <h5>{plans[firstPlan].options.team_members}</h5>
                </td>
                <td className="text-muted text-center align-middle">
                  <h5>{plans.g.options.team_members}</h5>
                </td>
                <td className="text-muted text-center align-middle">
                  <h5>{plans.p.options.team_members}</h5>
                </td>
              </tr>
              <tr>
                <th className="align-middle">
                  <h5>Key-owners</h5>
                  <p className="small text-muted">Each key owner can have one or more public SSH keys.</p>
                </th>
                <td className="text-muted text-center align-middle">
                  <h5>{plans[firstPlan].options.ag_limit > 0 ? plans[firstPlan].options.ag_limit : 'Unlimited'}</h5>
                </td>
                <td className="text-muted text-center align-middle">
                  <h5>{plans.g.options.ag_limit > 0 ? plans.g.options.ag_limit : 'Unlimited'}</h5>
                </td>
                <td className="text-muted text-center align-middle">
                  <h5>{plans.p.options.ag_limit > 0 ? plans.p.options.ag_limit : 'Unlimited'}</h5>
                </td>
              </tr>
              <tr>
                <th className="align-middle">
                  <h5>Administrative API</h5>
                  <p className="small text-muted">Manage Key Owners, Groups, SSH Keys via API</p>
                </th>
                <td className="text-muted text-center align-middle">
                  <h5>-</h5>
                </td>
                <td className="text-muted text-center align-middle">
                  <h5>YES</h5>
                </td>
                <td className="text-muted text-center align-middle">
                  <h5>YES</h5>
                </td>
              </tr>
              <tr>
                <th className="align-middle">
                  <h5>Free API calls</h5>
                  <p className="small text-muted">
                    When you exceed your free API calls, you will be billed $1 every 10k API calls.
                  </p>
                </th>
                <td className="text-muted text-center align-middle">
                  <h5>
                    {plans[firstPlan].options.api_calls > 0
                      ? `${plans[firstPlan].options.api_calls / 1000}k per month`
                      : 'Unlimited'}
                  </h5>
                </td>
                <td className="text-muted text-center align-middle">
                  <h5>
                    {plans.g.options.api_calls > 0 ? `${plans.g.options.api_calls / 1000}k per month` : 'Unlimited'}
                  </h5>
                </td>
                <td className="text-muted text-center align-middle">
                  <h5>
                    {plans.p.options.api_calls > 0 ? `${plans.p.options.api_calls / 1000}k per month` : 'Unlimited'}
                  </h5>
                </td>
              </tr>
              <tr>
                <th className="align-middle">
                  <h5>Server access logs</h5>
                </th>
                <td className="text-muted text-center align-middle">
                  <h5>
                    {plans[firstPlan].options.log_retention_days > 0
                      ? `${plans[firstPlan].options.log_retention_days} days`
                      : plans[firstPlan].options.log_retention_days < 0
                      ? 'Forever'
                      : '-'}
                  </h5>
                </td>
                <td className="text-muted text-center align-middle">
                  <h5>
                    {plans.g.options.log_retention_days > 0
                      ? `${plans.g.options.log_retention_days} days`
                      : plans.g.options.log_retention_days < 0
                      ? 'Forever'
                      : '-'}
                  </h5>
                </td>
                <td className="text-muted text-center align-middle">
                  <h5>
                    {plans.p.options.log_retention_days > 0
                      ? `${plans.p.options.log_retention_days} days`
                      : plans.p.options.log_retention_days < 0
                      ? 'Forever'
                      : '-'}
                  </h5>
                </td>
              </tr>
              <tr>
                <th className="align-middle">
                  <h5>AuthKeys operation logs</h5>
                  <p className="small text-muted">
                    These include signin in and out of the web console,
                    <br />
                    creating key owners and groups,
                    <br />
                    allocating permissions, etc.
                  </p>
                </th>
                <td className="text-muted text-center align-middle">
                  <h5>
                    {plans[firstPlan].options.audit_retention_limit === 0
                      ? '-'
                      : plans[firstPlan].options.audit_retention_limit > 0
                      ? `Last ${thousands_separators(plans[firstPlan].options.audit_retention_limit)} operations`
                      : 'All operations'}
                  </h5>
                </td>
                <td className="text-muted text-center align-middle">
                  <h5>
                    {plans.g.options.audit_retention_limit > 0
                      ? `Last ${thousands_separators(plans.g.options.audit_retention_limit)} operations`
                      : 'All operations'}
                  </h5>
                </td>
                <td className="text-muted text-center align-middle">
                  <h5>
                    {plans.p.options.audit_retention_limit > 0
                      ? `Last ${thousands_separators(plans.p.options.audit_retention_limit)} operations`
                      : 'All operations'}
                  </h5>
                </td>
              </tr>
              <tr>
                <th className="align-top">
                  <h5>Support</h5>
                  <p className="small text-muted">
                    * Business hours are defined as 9:00 AM to 6:00 PM in Europe/Amsterdam TZ, excluding holidays and
                    weekend
                    <br />
                    ** Extended Business hours are defined as 7:00 AM to 7:00 PM in Europe/Amsterdam TZ, excluding
                    holidays and weekend
                  </p>
                </th>
                <td className="text-muted text-left">
                  <p>
                    <span>Business hours* email access to Support Associates</span>
                  </p>
                  <dl className="mt-3">
                    <dt>General guidance</dt>
                    <dd>&lt; 24 business hours*</dd>
                    <dt>System impaired</dt>
                    <dd> &lt; 12 business hours*</dd>
                  </dl>
                </td>
                <td className="text-muted text-left">
                  <p>
                    <span>Extended Business hours** email access to Support Associates</span>
                  </p>
                  <dl className="mt-3">
                    <dt>General guidance</dt>
                    <dd>&lt; 12 extended business hours**</dd>
                    <dt>System impaired</dt>
                    <dd> &lt; 8 extended business hours**</dd>
                  </dl>
                </td>
                <td className="text-muted text-left">
                  <p>
                    <span>24/7 email access to Support Associates</span>
                  </p>
                  <dl className="mt-3">
                    <dt>General guidance</dt>
                    <dd>&lt; 8 hours</dd>
                    <dt>System impaired</dt>
                    <dd> &lt; 4 hours</dd>
                  </dl>
                </td>
              </tr>
              {getHelpCenter() && (
                <tr>
                  <th className="align-middle">
                    <h5>Help center access</h5>
                  </th>
                  <td className="text-muted text-center align-middle">Included</td>
                  <td className="text-muted text-center align-middle">Included</td>
                  <td className="text-muted text-center align-middle">Included</td>
                </tr>
              )}
              <tr>
                <td />
                <td className="text-center">
                  <a href={`${console}/signup?plan=silver`} className="btn btn-lg btn-primary">
                    Sign up
                  </a>
                </td>
                <td className="text-center">
                  <a href={`${console}/signup?plan=gold`} className="btn btn-lg btn-primary">
                    Sign up
                  </a>
                </td>
                <td className="text-center">
                  <a href={`${console}/signup?plan=platinum`} className="btn btn-lg btn-primary">
                    Sign up
                  </a>
                </td>
                <td className="d-none d-lg-table-cell text-center">
                  <Link to="/contact-us" className="btn btn-lg btn-primary">
                    Contact us
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {trialDays > 0 && (
          <div className="mb-4 text-center">
            <div className="alert alert-secondary" style={{ fontSize: '1.2em' }}>
              Try AuthKeys free for {trialDays} days!
            </div>
          </div>
        )}

        <div className="mt-5 d-lg-none">
          <div className="alert alert-info">
            We can offer you
            <br /> a custom plan if <br />
            the standard plans don't
            <br />
            fit your needs.
            <div className="mt-3">
              <Link to="/contact-us" className="btn btn-primary w-100">
                Contact us
              </Link>
            </div>
          </div>
        </div>
        <div className="mt-5 text-center">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://github.com/theoapp/theo-node"
            className="btn btn-lg btn-outline-primary p-3"
            style={{ fontSize: '1.2em' }}
          >
            You can also try Theo, the open source version of AuthKeys, for free. Check it out on GitHub
          </a>
        </div>
      </div>
    );
  }

  componentDidMount() {
    fetch('/api/plans', {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    })
      .then(res => {
        if (!res.ok) {
          throw Error(res.statusText);
        }
        return res.json();
      })
      .then(data => {
        this.setState({ plans: data });
      })
      .catch(err => {
        console.error(err);
      });
  }
}

export default PricingBlock;
