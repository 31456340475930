import React, { Component } from 'react';
import { setDocumentTitle } from '../../lib/utils/siteUtils';
import { Link } from 'react-router-dom';

const ops = ['+', '-'];

class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      n1: -1,
      n2: -1,
      op: -1,
      result: '',
      email: '',
      name: '',
      message: '',
      invalid: true,
      sent: false,
      error: false,
      error_reason: false
    };
  }
  render() {
    return (
      <div className="contact-wrapper">
        <div className="p-3 bg-primary text-light text-center">
          <h1 className="mb-0">Contact us</h1>
        </div>

        <div className="bg-light py-5">
          <div className="container text-primary">
            <div className="row">
              <div className="col-12 col-lg-6 offset-lg-3">
                {this.state.error && (
                  <div className="text-center mb-3">
                    <div className="alert alert-danger" role="alert">
                      {this.state.error_reason && <span>{this.state.error_reason}</span>}
                      {!this.state.error_reason && (
                        <span>Ops... sorry something went wrong, please retry in few minutes...</span>
                      )}
                    </div>
                  </div>
                )}
                {this.state.sent && (
                  <div className="text-center">
                    <div className="alert alert-info">
                      <p>
                        <strong>Thank you!</strong> You'll hear from us very soon!
                      </p>
                    </div>
                    <Link to="/" className="btn btn-lg btn-primary">
                      CONTINUE
                    </Link>
                  </div>
                )}
                {!this.state.sent && (
                  <form className="mtd-10 " method="POST" onSubmit={this.submitForm} autoComplete="off">
                    <div className="form-group">
                      <label htmlFor="inputEmail">Your email address</label>
                      <input
                        className="form-control"
                        type="email"
                        id="inputEmail"
                        name="email"
                        required="required"
                        value={this.state.email}
                        onChange={this.onChange}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="inputName">Your name</label>
                      <input
                        className="form-control"
                        type="text"
                        id="inputName"
                        name="name"
                        required="required"
                        value={this.state.name}
                        onChange={this.onChange}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="inputMessage">Your Message</label>
                      <textarea
                        className="form-control"
                        id="inputMessage"
                        name="message"
                        required="required"
                        rows="8"
                        onChange={this.onChange}
                        value={this.state.message}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="inputResult">
                        Solve{' '}
                        <i>
                          <span dangerouslySetInnerHTML={this.getHtmlEntities(this.state.n1)} /> {ops[this.state.op]}{' '}
                          <span dangerouslySetInnerHTML={this.getHtmlEntities(this.state.n2)} />
                        </i>
                      </label>
                      <div className="row">
                        <div className="col-lg-3 col-md-4 col-sm-4">
                          <input
                            className="form-control"
                            type="number"
                            id="inputResult"
                            name="result"
                            required="required"
                            onChange={this.onChange}
                            value={this.state.result}
                          />
                        </div>
                      </div>
                    </div>
                    <button className="btn btn-primary btn-lg mt-3" type="submit" disabled={this.state.invalid}>
                      Send your message
                    </button>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    setDocumentTitle('Contact');
    this.loadOp();
  }

  loadOp() {
    fetch('/contact', {
      headers: {
        Accept: 'application/json'
      }
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        this.setState({ ...data, result: '' });
      });
  }
  getHtmlEntities = s => {
    return { __html: ' &#' + (48 + s) + '; ' };
  };

  submitForm = e => {
    e.preventDefault();
    this.sendMessage().finally();
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.checkForm();
    });
  };

  checkForm = () => {
    const { email, name, message, result } = this.state;
    let invalid;
    if (!email || !name || !message || !result) {
      invalid = true;
    }
    this.setState({ invalid });
  };

  sendMessage = async () => {
    const { email, name, message, result } = this.state;
    try {
      const res = await fetch('/contact', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, name, message, result: Number(result) })
      });
      if (!res.ok) {
        const json = await res.json();
        if (json.error) {
          this.loadOp();
          this.setState({ error: true, error_reason: json.error });
        } else {
          this.setState({ error: true, error_reason: false });
        }
      } else {
        this.setState({ sent: true, error: false, error_reason: false });
      }
    } catch (e) {
      console.error('catch fetch', e.message);
      this.setState({ error: true, error_reason: false });
    }
  };
}

export default Contact;
