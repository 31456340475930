import React from 'react';

export const WIP = () => {
  return (
    <div className="ak-bg-dark p-2 rounded-right rounded-left">
      <video
        poster="https://i.gifer.com/fetch/w300-preview/ff/ff88888459f390b30438e162769be571.gif"
        className="full-media"
        loop="loop"
        autoPlay="autoPlay"
        playsInline=""
      >
        <source src="https://i.gifer.com/Ao.mp4" type="video/mp4" />
      </video>
      <h2>We're still working on this page..</h2>
    </div>
  );
};
