import React from 'react';
import { Link } from 'react-router-dom';

export default function NotFound() {
  return (
    <div className="contact-wrapper">
      <div className="p-3 bg-primary text-light text-center">
        <h1>Page Not Found</h1>
      </div>

      <div className="bg-light py-5">
        <div className="container text-primary">
          <Link to="/" className="text-primary">
            Home page
          </Link>
        </div>
      </div>
    </div>
  );
}
