import React from 'react';
import { Link } from 'react-router-dom';
import Tour from '../Tour';

export const Features = function() {
  return (
    <div className="features-wrapper">
      <div className="p-3 bg-primary text-light text-center">
        <h1 className="mb-0">AuthKeys features</h1>
      </div>
      <div className="bg-light text-primary py-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-6 col-md-4 mb-4 px-4">
              <h3>
                <i className="bi bi-diagram-3-fill mr-3" /> Centralisation
              </h3>
              <p>Manage all public SSH keys of your organization in one place.</p>
            </div>
            <div className="col-12 col-sm-6 col-md-4 mb-4 px-4">
              <h3>
                <i className="bi bi-lock-fill mr-3" /> Control
              </h3>
              <p>Have always a full view of who can access what.</p>
            </div>
            <div className="col-12 col-sm-6 col-md-4 mb-4 px-4">
              <h3>
                <i className="bi bi-calendar-week-fill mr-3" /> Auditing
              </h3>
              <p>All operations are logged and archived.</p>
            </div>
            <div className="col-12 col-sm-6 col-md-4 mb-4 px-4">
              <h3>
                <i className="bi bi-back mr-3" /> Backups
              </h3>
              <p>We take care of your backups for you, so you don't have to worry about them.</p>
            </div>

            <div className="col-12 col-sm-6 col-md-4 mb-4 px-4">
              <h3>
                <i className="bi bi-journal-text mr-3" /> Logging
              </h3>
              <p>
                All accesses to your servers are logged.
                <br />
                This feature requires running OpenSSH 6.9 or higher on your servers.
              </p>
            </div>
            <div className="col-12 col-sm-6 col-md-4 mb-4 px-4">
              <h3>
                <i className="bi bi-people-fill mr-3" /> Team work
              </h3>
              <p>
                AuthKeys allows multiple accounts, so several people can access the AuthKeys instance and help with
                public SSH key management.
              </p>
            </div>
            <div className="col-12 col-sm-6 col-md-4 mb-4 px-4">
              <h3>
                <i className="bi bi-laptop-fill mr-3" /> Web Console
              </h3>
              <p>
                AuthKeys comes with a straightforward web interface. Familiar and easy to use, it allows you to manage
                your public SSH keys from anywhere on your browser.
              </p>
            </div>
            <div className="col-12 col-sm-6 col-md-4 mb-4 px-4">
              <h3>
                <i className="bi bi-shield-lock-fill mr-3" /> Security
              </h3>
              <p>
                All public SSH keys are signed by you. The AuthKeys agent will discard public SSH keys unsigned or with
                an invalid signature.
              </p>
            </div>
            <div className="col-12 col-sm-6 col-md-4 mb-4 px-4">
              <h3>
                <i className="bi bi-stopwatch-fill mr-3" /> No Lag
              </h3>
              <p>
                Any changes your make to key owners, public SSH keys or access permisssions take effect immediately. No
                more automatic scheduling delays!
              </p>
            </div>
            <div className="col-12 col-sm-6 col-md-4 mb-4 px-4">
              <h3>
                <i className="bi bi-globe2 mr-3" /> Low latency
              </h3>
              <p>
                AuthKeys runs on several kubernetes clusters around the globe, so you can choose the region closest to
                your server. If you want a cluster in a different region, <Link to="/contact-us">let us know!</Link>
              </p>
            </div>
            <div className="col-12 col-sm-6 col-md-4 mb-4 px-4">
              <h3>
                <i className="bi bi-code-square mr-3" /> Know-how
              </h3>
              <p>
                AuthKeys is brought to you by the creators of{' '}
                <a href="https://github.com/theoapp/" target="_blank" rel="noopener noreferrer">
                  the Theo open source project
                </a>
                . AuthKeys give you Theo-as-a-service with professional support in a high availability environment.
              </p>
            </div>
            <div className="col-12 col-sm-6 col-md-4 mb-4 px-4">
              <h3>
                <i className="bi bi-cloud-download-fill mr-3" /> Own your data
              </h3>
              <p>
                Your AuthKeys data is yours: you can export it at any time. If you want to move from Theo to AuthKeys,
                or from AuthKeys to Theo, you can export and then import your data with ease.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center p-3 text-light bg-primary">
        <h1 className="mb-0">Get a preview</h1>
      </div>

      <div className="bg-light text-primary">
        <Tour />
      </div>
    </div>
  );
};
