import React, { Component } from 'react';
import { setDocumentTitle } from '../../lib/utils/siteUtils';
import PricingBlock from './PricingBlock';
import { Link } from 'react-router-dom';

class Pricing extends Component {
  render() {
    return (
      <div className="pricing-wrapper">
        <div className="p-3 bg-primary text-light text-center">
          <h1 className="mb-0">Pricing</h1>
        </div>
        <div className="bg-light pb-5">
          <div className="container-fluid">
            <div className="row mt-3">
              <div className="col">
                <PricingBlock />
              </div>
            </div>
          </div>
        </div>
        <div className="p-3 bg-primary text-light text-center">
          <h1 className="mb-0">Billing questions</h1>
        </div>
        <div className="bg-light py-5">
          <div className="container-fluid text-primary">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-3 mt-3">
                <h3>Do I need a card to signup?</h3>
                <p>No. Free plan does not require you to enter any credit card</p>
              </div>
              <div className="col-12 col-md-6 col-lg-3 mt-3">
                <h3>How does your billing work?</h3>
                <p>
                  Upon sign up, when your trial period ends, you will be billed each month or year the amount of the
                  selected plan for the next month or year, depending on the payment frequency you chose. After that,
                  any extra API calls you use will be billed monthly - if a minimum of $ 5 is reached. If you decide to
                  cancel your account before your trial period ends, nothing is due in any case.
                </p>
              </div>
              <div className="col-12 col-md-6 col-lg-3 mt-3">
                <h3>Can I cancel at anytime?</h3>
                <p>
                  Sure. This will interrupt the service, revoking access to all of your data, so be sure to export it
                  before proceeding.
                </p>
              </div>
              <div className="col-12 col-md-6 col-lg-3 mt-3">
                <h3>Is there a discount?</h3>
                <p>
                  Yes! If you choose to pay annually, you get 2 months for free! Also, if you're happy using AuthKeys,
                  there is a referral program that let you earn free credits sharing it with friends and colleagues.
                </p>
              </div>
            </div>
            <div className="mt-5 text-center">
              <Link to="/contact-us" className="btn btn-lg btn-outline-primary p-3" style={{ fontSize: '1.2em' }}>
                Any other questions about billing? Get in touch
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    setDocumentTitle('Pricing');
  }
}

export default Pricing;
