import { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { GTAG_SITE_KEY } from '../../lib/utils/siteUtils';

class GoogleAnalytics extends PureComponent {
  static propTypes = {
    location: PropTypes.object,
    history: PropTypes.object
  };

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname === prevProps.location.pathname) {
      return;
    }
    if (this.props.history.action === 'PUSH' && typeof window.gtag === 'function') {
      this.sendData(this.props.location.pathname);
    }
  }

  render() {
    return this.props.children;
  }

  componentDidMount() {
    if (window.gtag) {
      this.sendData(this.props.location.pathname);
    }
  }

  sendData(pathname) {
    setTimeout(() => {
      const data = {
        page_title: document.title,
        page_location: window.location.href,
        page_path: pathname
      };
      window.gtag('config', GTAG_SITE_KEY, data);
    }, 10);
  }
}

export default withRouter(GoogleAnalytics);
