import React, { Component } from 'react';
import { setDocumentTitle } from '../../lib/utils/siteUtils';
import { WIP } from '../Common/WIP';

class Team extends Component {
  render() {
    return (
      <div className="container">
        <div className="p-3 ak-bg-dark rounded-pill">
          <h1>Company</h1>
        </div>

        <div className="m-5 text-center">
          <WIP />
        </div>
      </div>
    );
  }

  componentDidMount() {
    setDocumentTitle('Team');
  }
}

export default Team;
