import React, { Component } from 'react';
import { setDocumentTitle } from '../../lib/utils/siteUtils';
import { ReactComponent as Icon1 } from './1.svg';
import { ReactComponent as Icon2 } from './2.svg';
import { ReactComponent as Icon3 } from './3.svg';
import { ReactComponent as Icon4 } from './4.svg';
import { ReactComponent as Icon5 } from './5.svg';
import { ReactComponent as Icon6 } from './6.svg';
import { ReactComponent as Icon7 } from './7.svg';
import { ReactComponent as Icon8 } from './8.svg';
import { ReactComponent as Icon9 } from './9.svg';
import { ReactComponent as Icon10 } from './10.svg';
import { ReactComponent as Icon11 } from './11.svg';
import { ReactComponent as Icon12 } from './12.svg';
import { ReactComponent as Icon13 } from './13.svg';
import { ReactComponent as Icon14 } from './14.svg';
import { ReactComponent as Icon15 } from './15.svg';
import { ReactComponent as Icon16 } from './16.svg';

import { DoodleLibraryDisclaymer } from '../Common/DoodleLibraryDisclaimer';
import Tour from '../Tour';

class HowItWorks extends Component {
  render() {
    return (
      <div className="howitworks-wrapper">
        <div className="p-3 bg-primary text-light text-center">
          <h1 className="mb-0">How AuthKeys works</h1>
        </div>
        <div className="bg-light text-primary py-5">
          <div className="container-fluid">
            <div className="row mb-5">
              <div className="col-12 px-4">
                <h1>Setting up and configuring AuthKeys</h1>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-12 col-sm-6 col-lg-3 mb-5 mb-sm-4">
                <div className="mb-3 px-5 px-lg-0">
                  <Icon1 />
                </div>
                When you sign up to AuthKeys, your workstation generates an encrypted RSA certificate. This will be your
                “admin cert”.
              </div>
              <div className="col-12 col-sm-6 col-lg-3 mb-5 mb-sm-4">
                <div className="mb-3 px-5 px-lg-0">
                  <Icon2 />
                </div>
                The encrypted admin cert is sent to AuthKeys. We use it to create your very own AuthKeys instance.
              </div>
              <div className="col-12 col-sm-6 col-lg-3 mb-5 mb-sm-4">
                <div className="mb-3 px-5 px-lg-0">
                  <Icon3 />
                </div>
                Once your AuthKeys instance is up an running, you download the AuthKeys agent to your workstation.
              </div>
              <div className="col-12 col-sm-6 col-lg-3 mb-5 mb-sm-4">
                <div className="mb-3 px-5 px-lg-0">
                  <Icon4 />
                </div>
                You configure the AuthKeys agent with your admin cert, and then install the agent on your servers.
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-12 px-4">
                <h1>Adding key owners and their public SSH keys</h1>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-12 col-sm-6 col-lg-3 mb-5 mb-sm-4">
                <div className="mb-3 px-5 px-lg-0">
                  <Icon5 />
                </div>
                Create a key owner in your AuthKeys instance and assign the relevant permissions (e.g. root access on{' '}
                <em>server_1</em>).
              </div>
              <div className="col-12 col-sm-6 col-lg-3 mb-5 mb-sm-4">
                <div className="mb-3 px-5 px-lg-0">
                  <Icon6 />
                </div>
                Add the key owner’s public SSH key to your AuthKeys instance. Each key owner can have one or more public
                SSH keys.
              </div>
              <div className="col-12 col-sm-6 col-lg-3 mb-5 mb-sm-4">
                <div className="mb-3 px-5 px-lg-0">
                  <Icon7 />
                </div>
                Your workstation will fetch the admin cert, unlock it, and use it to sign the key owner’s public SSH
                key.
              </div>
              <div className="col-12 col-sm-6 col-lg-3 mb-5 mb-sm-4">
                <div className="mb-3 px-5 px-lg-0">
                  <Icon8 />
                </div>
                The key owner’s public SSH key, signed with your admin cert, is now stored in your AuthKeys instance.
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-12 px-4">
                <h1>Handling SSH access requests</h1>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-12 col-sm-6 col-lg-3 mb-5 mb-sm-4">
                <div className="mb-3 px-5 px-lg-0">
                  <Icon9 />
                </div>
                A key owner requests SSH access to one of your servers (e.g. root@<em>server_1</em>).
              </div>
              <div className="col-12 col-sm-6 col-lg-3 mb-5 mb-sm-4">
                <div className="mb-3 px-5 px-lg-0">
                  <Icon10 />
                </div>
                In <em>server_1</em>, sshd executes the AuthKeys agent, which requests the public SSH keys with
                permission to access as <em>root</em> from your AuthKeys instance.
              </div>
              <div className="col-12 col-sm-6 col-lg-3 mb-5 mb-sm-4">
                <div className="mb-3 px-5 px-lg-0">
                  <Icon11 />
                </div>
                In <em>server_1</em>, the AuthKeys agent verifies the signatures of the received public SSH keys. It
                returns to sshd only the keys with valid signatures.
              </div>
              <div className="col-12 col-sm-6 col-lg-3 mb-5 mb-sm-4">
                <div className="mb-3 px-5 px-lg-0">
                  <Icon12 />
                </div>
                sshd grants access, and the key owner is allowed to log into <em>server_1</em> as <em>root</em>.
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-12 px-4">
                <h1>Revoking access</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-6 col-lg-3 mb-5 mb-sm-4">
                <div className="mb-3 px-5 px-lg-0">
                  <Icon13 />
                </div>
                Set an expiration date for key owners. The key owner will be automatically disabled on that date,
                revoking all access.
              </div>
              <div className="col-12 col-sm-6 col-lg-3 mb-5 mb-sm-4">
                <div className="mb-3 px-5 px-lg-0">
                  <Icon14 />
                </div>
                Disable a key owner at any time from the web console with a single click. Disabling the key owner will
                revoke all access. You can re-activate the key owner at any time.
              </div>
              <div className="col-12 col-sm-6 col-lg-3 mb-5 mb-sm-4">
                <div className="mb-3 px-5 px-lg-0">
                  <Icon15 />
                </div>
                Delete a key owner from your AuthKeys instance if you know they will never need access again.
              </div>
              <div className="col-12 col-sm-6 col-lg-3 mb-5 mb-sm-4">
                <div className="mb-3 px-5 px-lg-0">
                  <Icon16 />
                </div>
                Delete a public SSH key without disabling or deleting the key owner. Ideal for handling compromised
                keys.
              </div>
            </div>

            <DoodleLibraryDisclaymer />
          </div>
        </div>
        <div className="text-center p-3 text-light bg-primary">
          <h1 className="mb-0">Get a preview</h1>
        </div>

        <div className="bg-light text-primary">
          <Tour />
        </div>
      </div>
    );
  }

  componentDidMount() {
    setDocumentTitle('How AuthKeys works');
  }
}

export default HowItWorks;
